<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="chips">
      <v-chip
        v-for="seasonSerie in seriesList"
        :key="seasonSerie.shoulderHeight"
        prepend-icon="mdi-horse-variant"
        label
        :size="chipSize(seasonSerie.shoulderHeight)"
        @click="showRanking(seasonSerie.shoulderHeight)"
      >
        <div :class="chipClass(seasonSerie.shoulderHeight)">
          {{ seasonSerie.shoulderHeight }}
        </div></v-chip
      >
    </div>
    <v-divider :thickness="5"></v-divider>
    <v-dialog v-model="detailsVisible" width="750">
      <div class="details"></div>
      <v-sheet class="mx-auto" elevation="6">
        <div class="detailtitle">
          <strong>{{ selectedRanking.rider }}</strong
          ><br />
          <em>{{ selectedRanking.horse }}</em>
        </div>
        <v-divider :thickness="5"></v-divider>

        <v-table height="415px" fixed-header>
          <thead>
            <tr>
              <th class="text-center"><strong></strong></th>
              <th class="text-left"><strong>Event</strong></th>
              <th class="text-left"><strong>Reeks / ring</strong></th>
              <th class="text-center"><strong>Plaats</strong></th>
              <th class="text-center"><strong>Ptn</strong></th>
              <th class="text-center"><strong></strong></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="event in events" :key="event.ringGuid">
              <td class="text-center mouseclick">
                <div @click="showEventRanking(event.eventGuid, event.ringGuid)">
                  <v-icon icon="mdi-format-list-text"></v-icon>
                  <v-tooltip activator="parent" location="bottom"
                    >Toon de klassementen voor dit event</v-tooltip
                  >
                </div>
              </td>
              <td class="text-left">
                <div>
                  <strong>{{ event.event }}</strong
                  ><br />
                  <em>{{ event.period }}</em>
                </div>
              </td>
              <td class="text-left">
                {{ event.ring }}
              </td>
              <td class="text-center">
                <div>
                  {{ event.position }}
                </div>
              </td>
              <td class="text-center">
                <div>
                  {{ event.points }}
                </div>
              </td>
              <td class="text-center">
                <div v-if="event.counted == 1">
                  <v-icon icon="mdi-check-circle-outline"></v-icon>
                  <v-tooltip activator="parent" location="bottom"
                    >Deze uitslag telt mee voor de selectie</v-tooltip
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </v-table>
        <v-banner lines="one" density="compact">
          <template v-slot:actions>
            <v-btn @click="hideEvents">SLUIT</v-btn>
          </template>
        </v-banner>
      </v-sheet>
    </v-dialog>
    <v-table height="415px" fixed-header v-if="selectedSerie">
      <thead>
        <tr>
          <th class="text-center"><strong>Plaats</strong></th>
          <th class="text-left"><strong>Combinatie</strong></th>
          <th class="text-center">Ptn</th>
          <th class="text-center"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="ranking in rankings" :key="ranking.position">
          <td class="text-center">
            <div>
              {{ ranking.position }}
            </div>
          </td>
          <td>
            <strong>{{ ranking.rider }}</strong
            ><br />
            <em>{{ ranking.horse }}</em>
          </td>
          <td class="text-center">
            <div>
              {{ ranking.points }}
            </div>
          </td>
          <td
            class="text-center mouseclick"
            @click="showEvents(ranking.position)"
          >
            <v-icon>mdi-information-outline</v-icon>
            <v-tooltip activator="parent" location="bottom">Details</v-tooltip>
          </td>
        </tr>
      </tbody>
    </v-table>
    <v-divider :thickness="5"></v-divider>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      isLoading: false,
      error: null,
      selectedSerie: "",
      selectedRanking: null,
      detailsVisible: false,
    };
  },
  computed: {
    seriesList() {
      return this.$store.getters["seasons/seasonSeries"];
    },
    rankings() {
      return this.$store.getters["seasons/seasonRankings"];
    },
    events() {
      return this.$store.getters["seasons/seasonEvents"];
    },
  },
  methods: {
    async loadRankings() {
      this.isLoading = true;

      try {
        const season = this.$store.getters["getSeason"];
        const series = this.$store.getters["seasons/seasonSeries"];
        const serie = series.filter((s) => {
          return s.shoulderHeight == this.selectedSerie ? true : false;
        });
        if (serie && serie.length > 0) {
          await this.$store.dispatch("seasons/loadSeasonRankings", {
            seasonGuid: season.guid,
            kind: serie[0].kind,
            shoulderHeight: serie[0].shoulderHeight,
          });
        }
      } catch (err) {
        this.error =
          err.message ||
          "Er trad een probleem op bij het ophalen van de selectiegegevens!";
      }

      this.isLoading = false;
    },
    async loadEvents() {
      this.isLoading = true;

      try {
        const season = this.$store.getters["getSeason"];
        const series = this.$store.getters["seasons/seasonSeries"];
        const serie = series.filter((s) => {
          return s.shoulderHeight == this.selectedSerie ? true : false;
        });
        if (serie && serie.length > 0) {
          await this.$store.dispatch("seasons/loadSeasonEvents", {
            seasonGuid: season.guid,
            kind: serie[0].kind,
            shoulderHeight: serie[0].shoulderHeight,
            rider: this.selectedRanking.rider,
            horse: this.selectedRanking.horse,
          });
        }
      } catch (err) {
        this.error =
          err.message ||
          "Er trad een probleem op bij het ophalen van de selectiegegevens!";
      }

      this.isLoading = false;
    },
    chipSize(serie) {
      if (serie == this.selectedSerie) {
        return "x-large";
      }

      return "";
    },
    chipClass(serie) {
      if (serie == this.selectedSerie) {
        return "selectedserie";
      }

      return "";
    },
    showRanking(serie) {
      this.selectedSerie = serie;
      this.loadRankings();
    },
    showEvents(position) {
      const rankings = this.$store.getters["seasons/seasonRankings"];
      const ranking = rankings.filter((r) => {
        return r.position == position ? true : false;
      });

      if (ranking && ranking.length == 1) {
        this.selectedRanking = ranking[0];
        this.loadEvents();
        this.detailsVisible = true;
      }
    },
    async showEventRanking(eventGuid, ringGuid) {
      try {
        await this.$store
          .dispatch("rings/loadRings", {
            eventGuid: eventGuid,
          })
          .then(this.$router.push("/ranking/" + ringGuid));
      } catch (err) {
        this.error =
          err.message ||
          "Er trad een probleem op bij het ophalen van de reeksen & ringen!";
      }
    },
    hideEvents() {
      this.detailsVisible = false;
    },
    handleError() {
      this.error = null;
    },
  },
  created() {
    this.loadRankings();
  },
};
</script>

<style scoped>
.chips {
  justify-content: center;
  text-align: center;
  padding-bottom: 5px;
}

.selectedserie {
  font-weight: bolder;
}

.mouseclick {
  cursor: pointer;
}

.details {
  display: block;
  padding-left: 5px;
  width: 100%;
}

.detailtitle {
  padding: 10px;
}
</style>
