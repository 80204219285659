<template>
  <v-card flat>
    <v-toolbar color="primary" dark extended flat> </v-toolbar>

    <v-card class="mx-auto" max-width="800" style="margin-top: -64px">
      <v-toolbar flat>
        <v-toolbar-title class="text-grey">
          <v-icon>mdi-horse-variant</v-icon>EventingOne
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon>
          <router-link to="/"> <v-icon>mdi-home-outline</v-icon></router-link>
        </v-btn>

        <router-link to="/login">
          <v-btn icon v-if="!loggedIn">
            <v-icon>mdi-login</v-icon>
            <v-tooltip activator="parent" location="bottom"
              >Aanmelden</v-tooltip
            >
          </v-btn>
        </router-link>

        <router-link to="/eventselection">
          <v-btn icon v-if="loggedIn">
            <v-icon>mdi-horse-variant</v-icon>
            <v-tooltip activator="parent" location="bottom"
              >Zoek en selecteer een event</v-tooltip
            >
          </v-btn>
        </router-link>

        <router-link to="/following">
          <v-badge :content="following" v-if="loggedIn && following > 0">
            <v-icon icon="mdi-heart-outline"></v-icon>
            <v-tooltip activator="parent" location="bottom"
              >Combinaties die ik volg</v-tooltip
            >
          </v-badge>
        </router-link>

        &nbsp; &nbsp; &nbsp;

        <router-link to="/ranking">
          <v-btn icon v-if="loggedIn && eventIsSelected && isRingAvailable">
            <v-icon>mdi-format-list-text</v-icon>
            <v-tooltip activator="parent" location="bottom"
              >Klassementen</v-tooltip
            >
          </v-btn>
        </router-link>

        <router-link to="/registrations">
          <v-btn
            icon
            v-if="loggedIn && eventIsSelected && isRegistrationAvailable"
          >
            <v-icon>mdi-account-search</v-icon>
            <v-tooltip activator="parent" location="bottom"
              >Zoek een combinatie</v-tooltip
            >
          </v-btn>
        </router-link>

        <router-link to="/seasonselection">
          <v-btn icon v-if="loggedIn && isSeasonAvailable">
            <v-icon>mdi-finance</v-icon>
            <v-tooltip activator="parent" location="bottom"
              >Selectieklassementen / seizoen</v-tooltip
            >
          </v-btn>
        </router-link>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text style="height: 530px">
        <router-view :key="$route.fullPath" v-slot="slotProps">
          <transition name="route" mode="out-in">
            <component :is="slotProps.Component"></component>
          </transition>
        </router-view>
      </v-card-text>

      <v-banner lines="one" density="compact">
        <v-carousel cycle height="20" hide-delimiters :show-arrows="false">
          <v-carousel-item>
            <div class="d-flex fill-height justify-center align-center">
              <em
                ><small>
                  Photobooth nodig? Klik&nbsp;
                  <a href="https://www.grietgillisjans.be" target="_blank"
                    >hier</a
                  >
                </small></em
              >
            </div>
          </v-carousel-item>
          <v-carousel-item>
            <div class="d-flex fill-height justify-center align-center">
              <em
                ><small>
                  3D print? Klik&nbsp;
                  <a
                    href="https://print-outside-the-box.onrender.com/"
                    target="_blank"
                    >hier</a
                  ></small
                ></em
              >
            </div>
          </v-carousel-item>
          <v-carousel-item>
            <div class="d-flex fill-height justify-center align-center">
              <em
                ><small>
                  Zoek je een fotograaf? Klik&nbsp;
                  <a href="https://www.grietgillisjans.be" target="_blank"
                    >hier</a
                  >
                </small></em
              >
            </div>
          </v-carousel-item>
          <v-carousel-item>
            <div class="d-flex fill-height justify-center align-center">
              <em
                ><small>
                  Betaalbaar ledenbeheer? Klik&nbsp;
                  <a href="https://ledenfiches.be" target="_blank">hier</a>
                </small></em
              >
            </div>
          </v-carousel-item>
          <v-carousel-item>
            <div class="d-flex fill-height justify-center align-center">
              <em
                ><small>
                  Livestreamen? Klik&nbsp;
                  <a
                    href="https://ledenfiches.be/livestreamedsportevents"
                    target="_blank"
                    >hier</a
                  >
                </small></em
              >
            </div>
          </v-carousel-item>
        </v-carousel>
      </v-banner>

      <v-banner
        lines="one"
        icon="mdi-lightbulb-question"
        color="primary"
        density="compact"
      >
        <template v-slot:text> Created by G&sup2;Soft, Lochristi </template>

        <template v-slot:actions>
          <a href="https://www.eventing.one/site" target="_blank">
            <v-btn> Check it out </v-btn>
          </a>
        </template>
      </v-banner>
    </v-card>
  </v-card>
</template>

<script>
export default {
  data: () => ({}),
  components: {},
  computed: {
    loggedIn() {
      return this.$store.getters["loggedIn"];
    },
    eventIsSelected() {
      const event = this.$store.getters["getEvent"];
      return event && event.eventGuid ? true : false;
    },
    isRingAvailable() {
      return this.$store.getters["rings/ringAvailable"];
    },
    isRegistrationAvailable() {
      return this.$store.getters["registrations/registrationAvailable"];
    },
    isSeasonAvailable() {
      return this.$store.getters["seasons/seasonAvailable"];
    },
    didAutoLogout() {
      return this.$store.getters.didAutoLogout;
    },
    following() {
      const following = this.$store.getters["following"];
      if (following) {
        return following.length;
      }

      return 0;
    },
  },
  created() {
    if (process.env.NODE_ENV === "production") {
      fetch("./settings.txt", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        response.json().then((json) => {
          this.$store.dispatch("appConfig", {
            apiUrl: json.config.apiUrl,
            homePageUrl: json.config.homePageUrl,
          });
          this.$store.dispatch("tryLogin").then(() => {
            this.$store.dispatch("loadFollowing");
            this.$store.dispatch("seasons/loadSeasons");
          });
          /* const loggedIn = this.$store.getters["loggedIn"];
          if (loggedIn) {
            this.$store.dispatch("loadTeamConfirmationOptions");
          } */
        });
      });
    } else {
      console.log("Development environment");
      this.$store.dispatch("appConfig", {
        apiUrl: "https://www.eventing.one/api/v1/",
        homePageUrl: "https://www.grietgillisjans.be/photobooth",
      });
    }
  },
  watch: {
    didAutoLogout(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        this.$router.replace("/login");
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("./../settings.css");

* {
  box-sizing: border-box;
}

html {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
}

body {
  margin: 0;
  color: var(--var-font-color);
}

a {
  color: var(--var-font-hyperlink);
}

a:hover {
  color: var(--var-font-hyperlink-hover);
}

.route-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.route-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.route-leave-active {
  transition: all 0.3s ease-in;
}

.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateY(0);
}

#navigation-icon > i {
  cursor: pointer;
  font-size: 2rem;
  padding: 4px;
}
</style>
