let timer;

export default {
  appConfig(context, payload) {
    context.commit("setAppConfig", payload);
  },
  async login(context, payload) {
    let url = context.rootState.apiUrl + "authenticate.php";

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        login: payload.login,
        password: payload.login,
        returnSecureToken: true,
      }),
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok || !responseData.success) {
      const error = new Error(responseData.message || "Fout bij aanmelding");
      throw error;
    }

    const expiresIn = +responseData.expiresIn * 1000;
    const expirationDate = new Date().getTime() + expiresIn;

    // Check API-URL in tryLogin!
    const sessionApiUrl = context.rootState.apiUrl;
    const data = {
      sessionApiUrl: sessionApiUrl,
      token: responseData.jwt,
      tokenExpiration: expirationDate,
      id: responseData.id,
      name: responseData.name,
      email: responseData.email,
    };
    localStorage.setItem("EventingOne", JSON.stringify(data));

    timer = setTimeout(function () {
      context.dispatch("autoLogout");
    }, expiresIn);

    // console.log(data);
    context.commit("setLogin", data);
  },
  tryLogin(context) {
    const data = JSON.parse(localStorage.getItem("EventingOne"));
    if (!data) return;

    // APIUrl different -> DO NOT LOGIN!
    const sessionApiUrl = context.rootState.apiUrl;
    if (data.sessionApiUrl != sessionApiUrl) {
      localStorage.removeItem("EventingOne");
      return;
    }

    // Already expired? Clear localStorage
    const expiresIn = +data.tokenExpiration - new Date().getTime();
    if (expiresIn < 0) {
      localStorage.removeItem("EventingOne");
      return;
    }

    if (!data.token || !data.loginId) return;

    timer = setTimeout(function () {
      context.dispatch("autoLogout");
    }, expiresIn);

    context.commit("setLogin", data);
  },
  resetLogin(context) {
    localStorage.removeItem("EventingOne");

    clearTimeout(timer);

    context.commit("setLogin", {
      token: null,
      id: null,
      name: null,
      email: null,
    });
  },
  autoLogout(context) {
    context.dispatch("resetLogin");
    context.commit("setAutoLogout");
  },
  setEvent(context, payload) {
    context.commit("setEvent", payload);
  },
  setRegistration(context, payload) {
    context.commit("setRegistration", payload);
  },
  setRankingRingGuid(context, payload) {
    context.commit("setRankingRingGuid", payload);
  },
  setSeason(context, payload) {
    context.commit("setSeason", payload);
  },
  loadFollowing(context) {
    let following = [];
    if (localStorage.getItem("EventingOneFollowing")) {
      following = JSON.parse(localStorage.getItem("EventingOneFollowing"));
    }

    context.commit("setFollowing", following);
  },
  addFollowing(context, payload) {
    let following = [];
    if (localStorage.getItem("EventingOneFollowing")) {
      following = JSON.parse(localStorage.getItem("EventingOneFollowing"));
    }

    following.push(payload.registrationGuid);
    localStorage.setItem("EventingOneFollowing", JSON.stringify(following));
    context.commit("setFollowing", following);
  },
  removeFollowing(context, payload) {
    let following = [];
    if (localStorage.getItem("EventingOneFollowing")) {
      following = JSON.parse(localStorage.getItem("EventingOneFollowing"));
    }

    const index = following.indexOf(payload.registrationGuid);
    if (index >= 0) {
      following.splice(index, 1);
      localStorage.setItem("EventingOneFollowing", JSON.stringify(following));
      context.commit("setFollowing", following);
    }
  },
};
