<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <v-dialog v-model="eventsVisible" width="750">
      <div class="events"></div>
      <v-sheet class="mx-auto" elevation="6">
        <v-table height="415px" fixed-header>
          <thead>
            <tr>
              <th class="text-center"><strong></strong></th>
              <th class="text-left"><strong>Event</strong></th>
              <th class="text-left"><strong>Reeks / ring</strong></th>
              <th class="text-center"><strong>Plaats</strong></th>
              <th class="text-center"><strong></strong></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="event in events" :key="event.ringGuid">
              <td class="text-center mouseclick">
                <div
                  @click="
                    showRanking(
                      event.eventGuid,
                      event.ringGuid,
                      event.registrationGuid
                    )
                  "
                >
                  <v-icon icon="mdi-format-list-text"></v-icon>
                  <v-tooltip activator="parent" location="bottom"
                    >Toon klassement voor dit event</v-tooltip
                  >
                </div>
              </td>
              <td class="text-left">
                <div>
                  <strong>{{ event.eventName }}</strong
                  ><br />
                  <em>{{ event.eventPeriod }}</em>
                </div>
              </td>
              <td class="text-left">
                {{ event.ring }}
              </td>
              <td class="text-center">
                <div
                  v-if="
                    event.ranking == '1.' ||
                    event.ranking == '2.' ||
                    event.ranking == '3.'
                  "
                >
                  <v-icon :color="rankingColor(event.ranking)"
                    >mdi-trophy-outline</v-icon
                  >
                </div>
                <div v-else-if="event.position && event.position != ''">
                  {{ event.position }}
                </div>
                <div v-else>{{ event.total }}</div>
              </td>
            </tr>
          </tbody>
        </v-table>
        <v-banner lines="one" density="compact">
          <template v-slot:actions>
            <v-btn @click="hideEvents">SLUIT</v-btn>
          </template>
        </v-banner>
      </v-sheet>
    </v-dialog>
    <v-table height="500px" fixed-header>
      <thead>
        <tr>
          <th class="text-center"></th>
          <th class="text-left"><strong>Combinatie</strong></th>
          <th class="text-left"><strong>Event</strong></th>
          <th class="text-center"></th>
          <th class="text-center"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="following in followings"
          :key="following.registrationGuid"
          :class="selectedRegistrationClass(following.registrationGuid)"
        >
          <td
            @click="removeFollowing(following.registrationGuid)"
            class="text-center"
          >
            <v-icon>mdi-heart</v-icon>
            <v-tooltip activator="parent" location="bottom"
              >Deze combinatie niet meer volgen</v-tooltip
            >
          </td>
          <td class="text-left">
            <strong>{{ following.name }}</strong
            ><br />
            {{ following.horse }}<br />
            <div
              v-html="
                followingInfo(
                  following.number,
                  following.combination,
                  following.ring
                )
              "
            ></div>
          </td>
          <td class="text-left">
            <strong>{{ following.eventName }}</strong
            ><br />
            {{ following.eventPeriod }}
          </td>
          <td
            @click="showEvents(following.registrationGuid)"
            class="text-center mouseclick"
          >
            <v-icon>mdi-horse-variant</v-icon>
            <v-tooltip activator="parent" location="bottom"> Events </v-tooltip>
          </td>
          <td
            @click="
              showRanking(
                following.eventGuid,
                following.ringGuid,
                following.registrationGuid
              )
            "
            class="text-center mouseclick"
          >
            <v-icon>mdi-format-list-text</v-icon>
            <v-tooltip activator="parent" location="bottom">
              {{ following.ring }}
            </v-tooltip>
          </td>
          <td class="text-center">
            <div
              v-if="
                following.ranking == '1.' ||
                following.ranking == '2.' ||
                following.ranking == '3.'
              "
            >
              <v-icon :color="rankingColor(following.ranking)"
                >mdi-trophy-outline</v-icon
              >
            </div>
            <div v-else-if="following.position && following.position != ''">
              {{ following.position }}
            </div>
            <div v-else>{{ following.total }}</div>
          </td>
        </tr>
      </tbody>
    </v-table>
    <v-divider :thickness="5"></v-divider>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      eventsVisible: false,
      isLoading: false,
      error: null,
    };
  },
  computed: {
    followings() {
      const following = this.$store.getters["registrations/following"];
      return following;
    },
    events() {
      const event = this.$store.getters["registrations/events"];
      return event;
    },
  },
  methods: {
    async loadFollowing() {
      this.isLoading = true;

      try {
        await this.$store.dispatch("registrations/loadFollowing", {});
      } catch (err) {
        this.error =
          err.message ||
          "Er trad een probleem op bij het ophalen van de gevolgde combinaties!";
      }

      this.isLoading = false;
    },
    removeFollowing(registrationGuid) {
      const payload = {
        registrationGuid: registrationGuid,
      };
      this.$store.dispatch("removeFollowing", payload);
      this.loadFollowing();
    },
    selectedRegistrationClass(registrationGuid) {
      const registration = this.$store.getters["selectedRegistration"];
      if (
        registration &&
        registration.registrationGuid &&
        registration.registrationGuid == registrationGuid
      ) {
        return "selectedregistration";
      }

      return "";
    },
    async showRanking(eventGuid, ringGuid, registrationGuid) {
      let currentEvent = null;
      const events = this.$store.getters["events/events"];
      if (events) {
        const event = events.filter((e) => {
          return e.eventGuid == eventGuid ? true : false;
        });

        if (event && event.length > 0) {
          currentEvent = event[0];
        }
      }

      this.$store.dispatch("setEvent", currentEvent);

      try {
        await this.$store
          .dispatch("registrations/loadRegistrations", {
            eventGuid: eventGuid,
          })
          .then(() => {
            let currentRegistration = null;
            const registrations =
              this.$store.getters["registrations/registrations"];
            if (registrations) {
              const registration = registrations.filter((r) => {
                return r.registrationGuid == registrationGuid ? true : false;
              });

              if (registration && registration.length > 0) {
                currentRegistration = registration[0];
              }
            }

            this.$store.dispatch("setRegistration", currentRegistration);
          });
      } catch (err) {
        this.error =
          err.message ||
          "Er trad een probleem op bij het ophalen van de combinaties!";
      }

      try {
        await this.$store
          .dispatch("rings/loadRings", {
            eventGuid: eventGuid,
          })
          .then(this.$router.push("/ranking/" + ringGuid));
      } catch (err) {
        this.error =
          err.message ||
          "Er trad een probleem op bij het ophalen van de reeksen & ringen!";
      }
    },
    async showEvents(registrationGuid) {
      const followings = this.$store.getters["registrations/following"];
      const following = followings.filter((f) => {
        return f.registrationGuid == registrationGuid ? true : false;
      });

      if (following && following.length > 0) {
        try {
          await this.$store
            .dispatch("registrations/loadEventsByRider", {
              rider: following[0].name,
              horse: following[0].horse,
            })
            .then((this.eventsVisible = true));
        } catch (err) {
          this.error =
            err.message ||
            "Er trad een probleem op bij het ophalen van de events!";
        }
      }
    },
    hideEvents() {
      this.eventsVisible = false;
    },
    rankingColor(n) {
      if (n == "1.") {
        return "#ffd700";
      }

      if (n == "2.") {
        return "#c0c0c0";
      }

      if (n == "3.") {
        return "#cd7f32";
      }

      return "";
    },
    followingInfo(number, combination, ring) {
      let info = "<em><small>";
      if (number && number != "") {
        info = info + "Nr: " + number;
      }
      if (combination && combination != "") {
        info = info + ", " + combination;
      }

      info = info + " - reeks/ring: " + ring;
      info = info + "</small></em>";
      return info;
    },
    handleError() {
      this.error = null;
    },
  },
  created() {
    this.loadFollowing();
  },
};
</script>

<style scoped>
.selectedregistration {
  background-color: #e0f7df;
}

.mouseclick {
  cursor: pointer;
}

.events {
  display: block;
  padding-left: 5px;
  width: 100%;
}
</style>
