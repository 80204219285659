export default {
  async loadAlerts(context, payload) {
    let url = context.rootState.apiUrl + "alerts/getalerts.php";
    const token = context.rootState.token;
    const tournament_id = payload.tournament_id;

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        jwt: token,
        tournament_id: tournament_id,
      }),
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok || !responseData.success) {
      const error = new Error(
        responseData.message || "Fout bij het ophalen van de waarschuwingen"
      );
      throw error;
    }

    context.commit("setAlerts", responseData.data);
  },
};
