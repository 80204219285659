<template>
  <section class="notfound">
    <v-card>
      <h2>Pagina niet gevonden</h2>
      <p>Deze pagina kon niet gevonden worden!</p>
      <p><router-link to="/">Home</router-link>.</p>
    </v-card>
  </section>
</template>

<style scoped>
.notfound {
  text-align: center;
  width: 100%;
  margin: 30px auto;
  padding: 20px;
  box-shadow: 0 2px 3px #ccc;
}
</style>
