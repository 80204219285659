<template>
  <div>
    <div class="fix" v-if="!loggedIn">
      <v-sheet height="5%" class="login">
        <router-link to="/login">
          <v-btn prepend-icon="mdi-login"> Login</v-btn>
        </router-link>
      </v-sheet>
    </div>
    <v-sheet height="95%">
      <div class="d-flex justify-center">
        <iframe width="800" height="510" :src="homePageUrl"></iframe>
      </div>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: "HomePage",
  data() {
    return {
      loggedIn: null,
      isLoading: false,
      error: null,
    };
  },
  computed: {
    homePageUrl() {
      return this.$store.getters["homePageUrl"];
    },
  },
  methods: {},
  created() {
    this.loggedIn = this.$store.getters["loggedIn"];
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fix {
  position: absolute;
  top: 82px;
  right: 0;
}
</style>
