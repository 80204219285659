export default {
  apiUrl(state) {
    return state.apiUrl;
  },
  homePageUrl(state) {
    return state.homePageUrl;
  },
  loggedIn(state) {
    return !!state.id;
  },
  didAutoLogout(state) {
    return state.didAutoLogout;
  },
  getEvent(state) {
    return state.event;
  },
  getEventGuid(state) {
    return state.event.eventGuid;
  },
  getRankingRingGuid(state) {
    return state.rankingRingGuid;
  },
  getSeason(state) {
    return state.season;
  },
  selectedRegistration(state) {
    return state.registration;
  },
  following(state) {
    return state.following;
  },
};
