export default {
  async loadRings(context, payload) {
    let url = context.rootState.apiUrl + "ring/getrings.php";
    const token = context.rootState.token;

    // console.log(payload);

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        jwt: token,
        event_guid: payload.eventGuid,
      }),
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok || !responseData.success) {
      const error = new Error(
        responseData.message || "Fout bij het ophalen van de reeksen & ringen"
      );
      throw error;
    }

    context.commit("setRings", responseData.data);
  },
};
