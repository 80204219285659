<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="chips">
      <v-chip
        v-for="ring in ringList"
        :key="ring.ringGuid"
        :color="ringColor(ring.ringGuid)"
        prepend-icon="mdi-format-list-text"
        label
        :size="chipSize(ring.ringGuid)"
        @click="showRanking(ring.ringGuid)"
        ><v-tooltip activator="parent" location="bottom"
          >{{ ring.stateDescription }} {{ ring.ring }},
          {{ ring.registrations }} combinaties</v-tooltip
        >
        <div :class="chipClass(ring.ringGuid)">{{ ring.ring }}</div></v-chip
      >
    </div>
    <v-divider :thickness="5"></v-divider>
    <v-dialog v-model="detailsVisible" width="750">
      <div class="details">
        <v-sheet class="mx-auto" elevation="6">
          <v-slide-group
            v-model="discipline"
            :key="discipline"
            class="pa-4"
            selected-class="bg-primary"
            show-arrows
            v-slot="{ isSelected, selectedClass }"
          >
            <v-slide-group-item v-if="eventDressage == 1">
              <v-card
                :class="['ma-4', selectedClass]"
                :color="getDisciplineColor(0)"
                class="details"
                height="60"
                @click="discipline = 0"
              >
                <v-icon>mdi-horse-variant</v-icon>
                <div>Dressuur</div>
              </v-card>
            </v-slide-group-item>
            <v-slide-group-item v-if="eventWay == 1">
              <v-card
                :class="['ma-4', selectedClass]"
                :color="getDisciplineColor(1)"
                class="details"
                height="60"
                @click="discipline = 1"
              >
                <v-icon>mdi-horse-variant</v-icon>
                <div>Wegparcours</div>
              </v-card>
            </v-slide-group-item>
            <v-slide-group-item v-if="eventCross == 1">
              <v-card
                :class="['ma-4', selectedClass]"
                :color="getDisciplineColor(2)"
                class="details"
                height="60"
                @click="discipline = 2"
              >
                <v-icon>mdi-horse-variant</v-icon>
                <div>Cross</div>
              </v-card>
            </v-slide-group-item>
            <v-slide-group-item v-if="eventJumping == 1">
              <v-card
                :class="['ma-4', selectedClass]"
                :color="getDisciplineColor(3)"
                class="details"
                height="60"
                @click="discipline = 3"
              >
                <v-icon>mdi-horse-variant</v-icon>
                <div>Jumping</div>
              </v-card>
            </v-slide-group-item>
            <div class="d-flex fill-height justify-center">
              <v-scale-transition>
                <v-icon
                  v-if="isSelected"
                  color="white"
                  size="12"
                  icon="mdi-check-circle-outline"
                ></v-icon>
              </v-scale-transition>
            </div>
          </v-slide-group>

          <v-expand-transition>
            <v-sheet
              class="overflow-auto"
              max-height="220"
              v-if="discipline != null"
            >
              <div class="disciplinedetails">
                <h3 class="text-h6">{{ getDisciplineTitle(discipline) }}</h3>
                <v-divider :thickness="5"></v-divider>
                <div v-html="getDisciplineInformation(discipline)"></div>
              </div>
            </v-sheet>
          </v-expand-transition>
          <v-banner lines="one" density="compact">
            <template v-slot:actions>
              <v-btn @click="hideDetails">SLUIT</v-btn>
            </template>
          </v-banner>
        </v-sheet>
      </div>
    </v-dialog>
    <v-table height="415px" fixed-header>
      <thead>
        <tr>
          <th class="text-center"></th>
          <th class="text-center"></th>
          <th class="text-left"><strong>Nr</strong></th>
          <th class="text-left"><strong>Combinatie</strong></th>
          <th class="text-center" v-if="eventDressage == 1">
            <strong>Dressuur</strong>
          </th>
          <th class="text-center" v-if="eventWay == 1"><strong>Weg</strong></th>
          <th class="text-center" v-if="eventCross == 1">
            <strong>Cross</strong>
          </th>
          <th class="text-center" v-if="eventJumping == 1">
            <strong>Jumping</strong>
          </th>
          <th class="text-center"></th>
          <th class="text-center"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="ranking in rankings"
          :key="ranking.id"
          :class="selectedRegistrationClass(ranking.registrationGuid)"
        >
          <td
            v-if="!isFollowing(ranking.registrationGuid)"
            @click="addFollowing(ranking.registrationGuid)"
            class="text-center mouseclick"
          >
            <v-icon>mdi-heart-outline</v-icon>
            <v-tooltip activator="parent" location="right"
              >Volg deze combinatie</v-tooltip
            >
          </td>
          <td
            v-if="isFollowing(ranking.registrationGuid)"
            @click="removeFollowing(ranking.registrationGuid)"
            class="text-center mouseclick"
          >
            <v-icon>mdi-heart</v-icon>
            <v-tooltip activator="parent" location="right"
              >Deze combinatie niet meer volgen</v-tooltip
            >
          </td>
          <td class="text-center">
            <div
              v-if="
                ranking.ranking == '1.' ||
                ranking.ranking == '2.' ||
                ranking.ranking == '3.'
              "
            >
              <v-icon :color="rankingColor(ranking.ranking)"
                >mdi-trophy-outline</v-icon
              >
            </div>
            <div v-else>
              {{ ranking.ranking }}
            </div>
          </td>
          <td>{{ ranking.number }}</td>
          <td>
            <strong>{{ ranking.name }}</strong
            ><br />
            <em>{{ ranking.horse }}</em>
          </td>
          <td class="text-center" v-if="eventDressage == 1">
            {{ ranking.dressagePoints }}<br />
            <strong>{{ ranking.dressagePenalties }}</strong>
          </td>
          <td class="text-center" v-if="eventWay == 1">
            <strong>{{ ranking.wayPenalties }}</strong>
          </td>
          <td class="text-center" v-if="eventCross == 1">
            {{ ranking.crossTime }}<br />
            {{ ranking.crossTimePenalties }}<br />
            {{ ranking.crossPoints }}<br />
            <strong>{{ ranking.crossPenalties }}</strong>
          </td>
          <td class="text-center" v-if="eventJumping == 1">
            {{ ranking.jumpingTime }}<br />
            {{ ranking.jumpingPoints }}<br />
            <strong>{{ ranking.jumpingPenalties }}</strong>
          </td>
          <td class="text-center">
            <strong>{{ ranking.total }}</strong>
          </td>
          <td
            class="text-center mouseclick"
            @click="showDetails(ranking.registrationGuid)"
          >
            <v-icon>mdi-information-outline</v-icon>
            <v-tooltip activator="parent" location="bottom">Details</v-tooltip>
          </td>
        </tr>
      </tbody>
    </v-table>
    <v-divider :thickness="5"></v-divider>
  </div>
</template>

<script>
export default {
  props: ["ringGuid"],
  components: {},
  data() {
    return {
      isLoading: false,
      error: null,
      guid: "",
      eventDressage: 0,
      eventWay: 0,
      eventCross: 0,
      eventJumping: 0,
      selectedRanking: null,
      detailsVisible: false,
      discipline: 0,
    };
  },
  computed: {
    rankings() {
      return this.$store.getters["rankings/rankings"];
    },
    ringList() {
      return this.$store.getters["rings/rings"];
    },
  },
  methods: {
    loadEventInfo() {
      const event = this.$store.getters["getEvent"];

      if (event) {
        this.eventDressage = event.dressage;
        this.eventWay = event.way;
        this.eventCross = event.cross;
        this.eventJumping = event.jumping;
      }
    },
    async loadRankings() {
      this.isLoading = true;

      try {
        await this.$store.dispatch("rankings/loadRankings", {
          ringGuid: this.guid,
        });
      } catch (err) {
        this.error =
          err.message ||
          "Er trad een probleem op bij het ophalen van het klassement!";
      }

      this.isLoading = false;
    },
    rankingColor(n) {
      if (n == "1.") {
        return "#ffd700";
      }

      if (n == "2.") {
        return "#c0c0c0";
      }

      if (n == "3.") {
        return "#cd7f32";
      }

      return "";
    },
    ringColor(ringGuid) {
      const rings = this.$store.getters["rings/rings"];

      let color = "error";
      if (rings) {
        const ring = rings.filter((r) => {
          return r.ringGuid == ringGuid ? true : false;
        });

        if (ring && ring.length > 0) {
          if (ring[0].state == 0) {
            color = "success";
          }
          if (ring[0].state == 1) {
            color = "warning";
          }
        }
      }

      return color;
    },
    selectedRegistrationClass(registrationGuid) {
      const registration = this.$store.getters["selectedRegistration"];
      if (
        registration &&
        registration.registrationGuid &&
        registration.registrationGuid == registrationGuid
      ) {
        return "selectedregistration";
      }

      return "";
    },
    chipSize(ringGuid) {
      if (ringGuid == this.guid) {
        return "x-large";
      }

      return "";
    },
    chipClass(ringGuid) {
      if (ringGuid == this.guid) {
        return "selectedring";
      }

      return "";
    },
    showRanking(ringGuid) {
      this.$store.commit("setRankingRingGuid", ringGuid);
      this.guid = ringGuid;
      this.loadRankings();
    },
    showDetails(guid) {
      const rankings = this.$store.getters["rankings/rankings"];
      const ranking = rankings.filter((r) => {
        return r.registrationGuid == guid ? true : false;
      });

      if (ranking && ranking.length == 1) {
        this.selectedRanking = ranking[0];
        this.detailsVisible = true;
      }
    },
    hideDetails() {
      this.detailsVisible = false;
    },
    getDisciplineTitle(discipline) {
      if (discipline == 1) {
        return "Wegparcours";
      }
      if (discipline == 2) {
        return "Cross";
      }
      if (discipline == 3) {
        return "Jumping";
      }

      return "Dressuur";
    },
    getDisciplineColor(discipline) {
      if (discipline == this.discipline) {
        return "success";
      }
      return "";
    },
    getDisciplineInformation(discipline) {
      if (discipline == 1) {
        return (
          "Strafpunten: <strong>" +
          (this.selectedRanking.wayPenalties
            ? this.selectedRanking.wayPenalties
            : "") +
          "</strong>" +
          "<hr>" +
          this.selectedRanking.wayDetails
        );
      }
      if (discipline == 2) {
        return (
          "Tijd: <strong>" +
          (this.selectedRanking.crossTime
            ? this.selectedRanking.crossTime
            : "") +
          "</strong><br>" +
          "Strafpunten tijd: <strong>" +
          (this.selectedRanking.crossTimePenalties
            ? this.selectedRanking.crossTimePenalties
            : "") +
          "</strong><br>" +
          "Strafpunten parcours: <strong>" +
          (this.selectedRanking.crossPoints
            ? this.selectedRanking.crossPoints
            : "") +
          "</strong><br>" +
          "Totaal: <strong>" +
          (this.selectedRanking.crossPenalties
            ? this.selectedRanking.crossPenalties
            : "") +
          "</strong>" +
          "<hr>" +
          this.selectedRanking.crossDetails
        );
      }
      if (discipline == 3) {
        return (
          "Strafpunten tijd: <strong>" +
          (this.selectedRanking.jumpingTime
            ? this.selectedRanking.jumpingTime
            : "") +
          "</strong><br>" +
          "Strafpunten parcours: <strong>" +
          (this.selectedRanking.jumpingPoints
            ? this.selectedRanking.jumpingPoints
            : "") +
          "</strong><br>" +
          "Totaal: <strong>" +
          (this.selectedRanking.jumpingPenalties
            ? this.selectedRanking.jumpingPenalties
            : "") +
          "</strong>" +
          "<hr>" +
          this.selectedRanking.jumpingDetails
        );
      }

      return (
        "Ptn: <strong>" +
        (this.selectedRanking.dressagePoints
          ? this.selectedRanking.dressagePoints
          : "") +
        "</strong><br>" +
        "Strafptn: <strong>" +
        (this.selectedRanking.dressagePenalties
          ? this.selectedRanking.dressagePenalties
          : "") +
        "</strong>" +
        "<hr>" +
        this.selectedRanking.dressageDetails
      );
    },
    isFollowing(registrationGuid) {
      const following = this.$store.getters["following"];
      return following.includes(registrationGuid) ? true : false;
    },
    addFollowing(registrationGuid) {
      const payload = {
        registrationGuid: registrationGuid,
      };
      this.$store.dispatch("addFollowing", payload);
    },
    removeFollowing(registrationGuid) {
      const payload = {
        registrationGuid: registrationGuid,
      };
      this.$store.dispatch("removeFollowing", payload);
    },
    handleError() {
      this.error = null;
    },
  },
  created() {
    if (this.ringGuid && this.ringGuid != "") {
      this.guid = this.ringGuid;
    } else {
      const rings = this.$store.getters["rings/rings"];
      const ringGuid = this.$store.getters["getRankingRingGuid"];
      if (rings && rings.length > 0) {
        if (ringGuid && ringGuid != "") {
          const ring = rings.filter((r) => {
            return r.ringGuid == ringGuid ? true : false;
          });

          if (ring && ring.length > 0) {
            this.guid = ring[0].ringGuid;
          }
        }

        if (this.guid == "") {
          this.guid = rings[0].ringGuid;
        }
      }
    }

    this.loadEventInfo();
    this.loadRankings();
  },
};
</script>

<style scoped>
.chips {
  justify-content: center;
  text-align: center;
  padding-bottom: 5px;
}

.selectedring {
  font-weight: bolder;
}

.selectedregistration {
  background-color: #e0f7df;
}

.mouseclick {
  cursor: pointer;
}

.details {
  display: block;
  padding-left: 5px;
  width: 100%;
}

.disciplinedetails {
  margin-left: 20px;
}
</style>
