import { createStore } from "vuex";
import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

import messagesModule from "./modules/messages/index.js";
import alertsModule from "./modules/alerts/index.js";
import eventsModule from "./modules/events/index.js";
import ringsModule from "./modules/rings/index.js";
import registrationsModule from "./modules/registrations/index.js";
import rankingsModule from "./modules/rankings/index.js";
import seasonsModule from "./modules/seasons/index.js";

const store = createStore({
  modules: {
    messages: messagesModule,
    alerts: alertsModule,
    events: eventsModule,
    rings: ringsModule,
    registrations: registrationsModule,
    rankings: rankingsModule,
    seasons: seasonsModule,
  },
  state() {
    return {
      apiUrl: "",
      homePageUrl: "",

      token: null,
      id: null,
      name: null,
      email: null,

      event: null,
      registration: null,
      rankingRingGuid: null,
      season: null,

      following: [],
    };
  },

  mutations,
  actions,
  getters,
});

export default store;
