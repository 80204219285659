export default {
  registrations(state) {
    return state.registrations;
  },
  following(state) {
    return state.following;
  },
  events(state) {
    return state.events;
  },
  registrationAvailable(state) {
    return state.registrations && state.registrations.length > 0 ? true : false;
  },
};
