import { createRouter, createWebHistory } from "vue-router";

import store from "./store/index.js";

import HomePage from "./pages/HomePage.vue";
import NotFound from "./pages/NotFound.vue";
import Login from "./pages/Login.vue";
import EventSelection from "./pages/events/EventSelection.vue";
import RegistrationSelection from "./pages/registrations/RegistrationSelection.vue";
import Ranking from "./pages/rankings/Ranking.vue";
import Following from "./pages/following/Following.vue";
import SeasonSelection from "./pages/seasons/SeasonSelection.vue";
import SeasonRanking from "./pages/seasons/SeasonRanking.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: HomePage },
    { path: "/home", component: HomePage },
    { path: "/login", component: Login },
    {
      path: "/eventselection",
      component: EventSelection,
      meta: { requiresAuth: true },
    },
    {
      path: "/ranking",
      component: Ranking,
      meta: { requiresAuth: true },
    },
    {
      path: "/ranking/:ringGuid",
      component: Ranking,
      meta: { requiresAuth: true },
      props: true,
    },
    {
      path: "/registrations",
      component: RegistrationSelection,
      meta: { requiresAuth: true },
    },
    {
      path: "/following",
      component: Following,
      meta: { requiresAuth: true },
    },
    {
      path: "/seasonselection",
      component: SeasonSelection,
      meta: { requiresAuth: true },
    },
    {
      path: "/seasonserie",
      component: SeasonRanking,
      meta: { requiresAuth: true },
    },

    { path: "/:notFound(.*)", component: NotFound },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (store.state.id) {
      next();
    } else {
      next("/");
    }
  } else {
    next();
  }
});

export default router;
