export default {
  setSeasons(state, payload) {
    state.seasons = payload;
  },
  setSeasonSeries(state, payload) {
    state.seasonSeries = payload;
  },
  setSeasonRankings(state, payload) {
    state.seasonRankings = payload;
  },
  setSeasonEvents(state, payload) {
    state.seasonEvents = payload;
  },
};
