<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div id="signin">
    <div class="signin-form">
      <form @submit.prevent="onSubmit">
        <div class="input">
          <v-text-field
            type="text"
            id="code"
            placeholder="Tornooicode"
            label="Tornooicode"
            v-model="login.login"
            @blur="clearValidity"
            @focus="clearValidity"
          ></v-text-field>
        </div>
        <p></p>
        <div v-if="!formIsValid">
          <p>Breng een geldige tornooicode in!</p>
        </div>
        <div class="submit">
          <v-btn type="submit" :loading="isLoading"
            ><v-icon icon="mdi-chart-line"></v-icon>&nbsp;Show me the results!!!

            <template v-slot:loader>
              <v-progress-linear indeterminate></v-progress-linear>
            </template>
          </v-btn>
        </div>
        <div v-if="!!error" class="error">
          <v-card title="FOUT" :text="error" variant="tonal"> </v-card>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { reactive, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();

    let login = reactive({
      login: "",
    });

    login.login = "LRV";

    let error = ref("");
    let isLoading = ref(false);
    let formIsValid = ref(true);

    const loggedIn = computed(function () {
      return store.getters.loggedIn;
    });

    async function onSubmit() {
      formIsValid.value = true;

      if (login.login === "") {
        formIsValid.value = false;
      } else {
        isLoading.value = true;

        const formData = {
          login: login.login,
        };

        try {
          await store
            .dispatch("login", {
              login: formData.login,
            })
            .then(() => {
              store.dispatch("loadFollowing");
              store.dispatch("seasons/loadSeasons");
            });

          router.push("/eventselection");
        } catch (err) {
          error.value =
            err.message || "Fout bij het aanmelden. Probeer later opnieuw.";
        }

        isLoading.value = false;
      }
    }

    function clearValidity() {
      formIsValid.value = true;
      error.value = null;
    }

    return {
      login,
      loggedIn,
      formIsValid,
      isLoading,
      clearValidity,
      error,
      onSubmit,
    };
  },
};
</script>

<style scoped>
.signin-form {
  margin: 30px auto;
  padding: 20px;
  box-shadow: 0 2px 3px #ccc;
}

.error {
  text-align: left;
  margin: 20px auto;
}
</style>
