export default {
  seasons(state) {
    return state.seasons;
  },
  seasonAvailable(state) {
    return state.seasons && state.seasons.length > 0 ? true : false;
  },
  seasonSeries(state) {
    return state.seasonSeries;
  },
  seasonSeriesAvailable(state) {
    return state.seasonSeries && state.seasonSeries.length > 0 ? true : false;
  },
  seasonRankings(state) {
    return state.seasonRankings;
  },
  seasonRankingAvailable(state) {
    return state.seasonRankings && state.seasonRankings.length > 0
      ? true
      : false;
  },
  seasonEvents(state) {
    return state.seasonEvents;
  },
  seasonEventsAvailable(state) {
    return state.seasonEvents && state.seasonEvents.length > 0 ? true : false;
  },
};
