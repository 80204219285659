export default {
  setAppConfig(state, payload) {
    state.apiUrl = payload.apiUrl;
    state.homePageUrl = payload.homePageUrl;
  },
  setLogin(state, payload) {
    state.token = payload.token;
    state.id = payload.id;
    state.name = payload.name;
    state.email = payload.email;

    state.didAutoLogout = false;
  },
  setAutoLogout(state) {
    state.didAutoLogout = true;
  },
  setEvent(state, payload) {
    state.event = payload;
  },
  setRegistration(state, payload) {
    state.registration = payload;
  },
  setRankingRingGuid(state, payload) {
    state.rankingRingGuid = payload;
  },
  setSeason(state, payload) {
    state.season = payload;
  },
  setFollowing(state, payload) {
    state.following = payload;
  },
};
