<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="main">
    <v-autocomplete
      v-model="eventGuid"
      :items="eventList"
      :item-props="itemProps"
      item-title="name"
      item-value="eventGuid"
      label="Zoek event"
      no-data-text="Geen events"
      placeholder="Selecteer event"
      single-line
      clearable
    ></v-autocomplete>

    <div v-if="eventGuid" class="info">
      <v-card>
        <h1><v-icon>mdi-horse-variant</v-icon>&nbsp;{{ event.name }}</h1>
        <v-divider :thickness="5"></v-divider>
        <div class="details">
          <div>
            <v-icon>mdi-account-details-outline</v-icon>&nbsp;Organisator:
            <strong>{{ event.organiser }}</strong>
          </div>
          <div>
            <v-icon>mdi-calendar-blank-outline</v-icon>&nbsp;Periode:
            <strong>{{ event.period }}</strong>
          </div>
          <div>
            <v-icon>mdi-format-list-group</v-icon>&nbsp;Disciplines:
            <strong>{{ event.disciplines }}</strong>
          </div>
        </div>
        <v-divider :thickness="5"></v-divider>

        <div class="chips d-flex justify-center align-center ga-2 mt-2">
          <v-chip
            prepend-icon="mdi-format-list-text"
            label
            @click="showRankings"
            v-if="isRingAvailable"
            ><v-tooltip activator="parent" location="bottom"
              >Raadpleeg de klassementen</v-tooltip
            >Klassementen</v-chip
          >

          <v-chip
            prepend-icon="mdi-account-search"
            label
            @click="showCombinations"
            v-if="isRegistrationAvailable"
            ><v-tooltip activator="parent" location="bottom"
              >Zoek een combinatie</v-tooltip
            >Combinaties</v-chip
          >

          <!--
          <v-chip
            v-for="ring in ringList"
            :key="ring.ringGuid"
            :color="ringColor(ring.ringGuid)"
            prepend-icon="mdi-format-list-text"
            label
            @click="showRanking(ring.ringGuid)"
            ><v-tooltip activator="parent" location="bottom"
              >{{ ring.stateDescription }} {{ ring.ring }},
              {{ ring.registrations }} combinaties</v-tooltip
            >{{ ring.ring }}</v-chip
          >
          -->
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "eventsPage",
  data: () => ({
    isLoading: false,
    error: null,
    eventGuid: null,
    events: [],
  }),
  computed: {
    eventList() {
      const events = this.$store.getters["events/events"];

      if (events) {
        const list = [];
        events.forEach((event) => {
          let name = event.period + " - " + event.name;

          let info = "";
          if (event.organiser) {
            info = event.organiser;
          }

          if (event.disciplines) {
            if (info) {
              info = info + " - ";
            }

            info = info + event.disciplines;
          }

          list.push({
            eventGuid: event.eventGuid,
            name: name,
            info: info,
          });
        });

        return list;
      }

      return [];
    },
    event() {
      const event = this.$store.getters["getEvent"];
      return event;
    },
    ringList() {
      return this.$store.getters["rings/getRings"];
    },
    isRingAvailable() {
      return this.$store.getters["rings/ringAvailable"];
    },
    isRegistrationAvailable() {
      return this.$store.getters["registrations/registrationAvailable"];
    },
  },
  methods: {
    async loadEvents() {
      this.isLoading = true;

      try {
        await this.$store
          .dispatch("events/loadEvents", {})
          .then((this.eventGuid = this.$store.getters["getEventGuid"]));
      } catch (err) {
        this.error =
          err.message ||
          "Er trad een probleem op bij het ophalen van de events!";
      }

      this.isLoading = false;
    },
    itemProps(item) {
      if (item) {
        return {
          title: item.name,
          subtitle: item.info,
        };
      }

      return null;
    },
    async loadRings() {
      this.isLoading = true;

      try {
        await this.$store.dispatch("rings/loadRings", {
          eventGuid: this.eventGuid,
        });
      } catch (err) {
        this.error =
          err.message ||
          "Er trad een probleem op bij het ophalen van de reeksen & ringen!";
      }

      this.isLoading = false;
    },
    async loadRegistrations() {
      this.isLoading = true;

      try {
        await this.$store.dispatch("registrations/loadRegistrations", {
          eventGuid: this.eventGuid,
        });
      } catch (err) {
        this.error =
          err.message ||
          "Er trad een probleem op bij het ophalen van de combinaties!";
      }

      this.isLoading = false;
    },
    ringColor(ringGuid) {
      const rings = this.$store.getters["rings/rings"];

      let color = "error";
      if (rings) {
        const ring = rings.filter((r) => {
          return r.ringGuid == ringGuid ? true : false;
        });

        if (ring && ring.length > 0) {
          if (ring[0].state == 0) {
            color = "success";
          }
          if (ring[0].state == 1) {
            color = "warning";
          }
        }
      }

      return color;
    },
    showRanking(ringGuid) {
      this.$router.push("/ranking/" + ringGuid);
    },
    showRankings() {
      this.$router.push("/ranking");
    },
    showCombinations() {
      this.$router.push("/registrations");
    },
  },
  watch: {
    eventGuid: function (val) {
      let currentEvent = null;
      const events = this.$store.getters["events/events"];
      if (events) {
        const event = events.filter((e) => {
          return e.eventGuid == val ? true : false;
        });

        if (event && event.length > 0) {
          currentEvent = event[0];
        }
      }

      this.$store.dispatch("setEvent", currentEvent);
      this.loadRings();
      this.loadRegistrations();
    },
  },
  created() {
    this.loadEvents();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
html {
  font-family: "Roboto", sans-serif;
}

.main {
  margin: 30px auto;
  border: 1px solid var(--var-font-border);
  box-shadow: 0 2px 3px #ccc;
}

.info {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  line-height: 40px;
}

.details {
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.chips {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
