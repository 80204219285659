export default {
  async loadSeasons(context) {
    let url = context.rootState.apiUrl + "season/getseasons.php";
    const token = context.rootState.token;

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        jwt: token,
      }),
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok || !responseData.success) {
      const error = new Error(
        responseData.message || "Fout bij het ophalen van de seizoensinformatie"
      );
      throw error;
    }

    context.commit("setSeasons", responseData.data);
  },
  async loadSeasonSeries(context, payload) {
    let url = context.rootState.apiUrl + "season/getseasonseries.php";
    const token = context.rootState.token;

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        jwt: token,
        season_guid: payload.seasonGuid,
      }),
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok || !responseData.success) {
      const error = new Error(
        responseData.message || "Fout bij het ophalen van het seizoensreeksen"
      );
      throw error;
    }

    context.commit("setSeasonSeries", responseData.data);
  },
  async loadSeasonRankings(context, payload) {
    let url = context.rootState.apiUrl + "season/getseasonranking.php";
    const token = context.rootState.token;

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        jwt: token,
        season_guid: payload.seasonGuid,
        kind: payload.kind,
        shoulderheight: payload.shoulderHeight,
      }),
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok || !responseData.success) {
      const error = new Error(
        responseData.message || "Fout bij het ophalen van de selectiegegevens"
      );
      throw error;
    }

    context.commit("setSeasonRankings", responseData.data);
  },
  async loadSeasonEvents(context, payload) {
    let url = context.rootState.apiUrl + "season/getseasonevents.php";
    const token = context.rootState.token;

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        jwt: token,
        season_guid: payload.seasonGuid,
        kind: payload.kind,
        shoulderheight: payload.shoulderHeight,
        rider: payload.rider,
        horse: payload.horse,
      }),
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok || !responseData.success) {
      const error = new Error(
        responseData.message ||
          "Fout bij het ophalen van de seizoensklassementdetails"
      );
      throw error;
    }

    context.commit("setSeasonEvents", responseData.data);
  },
};
