export default {
  async loadRegistrations(context, payload) {
    let url = context.rootState.apiUrl + "registration/getregistrations.php";
    const token = context.rootState.token;

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        jwt: token,
        event_guid: payload.eventGuid,
      }),
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok || !responseData.success) {
      const error = new Error(
        responseData.message || "Fout bij het ophalen van de combinaties"
      );
      throw error;
    }

    context.commit("setRegistrations", responseData.data);
  },
  async loadEventsByRider(context, payload) {
    let url = context.rootState.apiUrl + "event/geteventsbyrider.php";
    const token = context.rootState.token;

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        jwt: token,
        rider: payload.rider,
        horse: payload.horse,
      }),
    });

    const responseData = await response.json();
    console.log(responseData);

    if (!response.ok || !responseData.success) {
      const error = new Error(
        responseData.message || "Fout bij het ophalen van de combinaties"
      );
      throw error;
    }

    context.commit("setEvents", responseData.data);
  },
  async loadFollowing(context) {
    let url =
      context.rootState.apiUrl + "registration/getregistrationsbyguid.php";
    const token = context.rootState.token;
    const guids = context.rootState.following;

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        jwt: token,
        guids: guids,
      }),
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok || !responseData.success) {
      const error = new Error(
        responseData.message ||
          "Fout bij het ophalen van de gevolgde combinaties"
      );
      throw error;
    }

    context.commit("setFollowing", responseData.data);
  },
};
