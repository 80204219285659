export default {
  setRegistrations(state, payload) {
    state.registrations = payload;
  },
  setEvents(state, payload) {
    state.events = payload;
  },
  setFollowing(state, payload) {
    state.following = payload;
  },
};
