export default {
  async loadRankings(context, payload) {
    let url = context.rootState.apiUrl + "event/getranking.php";
    const token = context.rootState.token;

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        jwt: token,
        ring_guid: payload.ringGuid,
      }),
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok || !responseData.success) {
      const error = new Error(
        responseData.message || "Fout bij het ophalen van het klassement"
      );
      throw error;
    }

    context.commit("setRankings", responseData.data);
  },
};
