<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="main">
    <v-autocomplete
      v-model="seasonGuid"
      :items="seasonList"
      :item-props="itemProps"
      item-title="name"
      item-value="seasonGuid"
      label="Zoek seizoen"
      no-data-text="Geen seizoenen"
      placeholder="Selecteer season"
      single-line
      clearable
    ></v-autocomplete>

    <div v-if="seasonGuid" class="info">
      <v-card>
        <h1>
          <v-icon>mdi-horse-variant</v-icon>&nbsp;{{ season.description }}
        </h1>
        <v-divider :thickness="5"></v-divider>
        <div class="details">
          <div>
            <v-icon>mdi-calendar-blank-outline</v-icon>&nbsp;Periode:
            <strong>{{ season.period }}</strong>
          </div>
        </div>
        <v-divider :thickness="5"></v-divider>

        <div class="chips d-flex justify-center align-center ga-2 mt-2">
          <v-chip
            prepend-icon="mdi-finance"
            label
            @click="showSeries"
            v-if="isSeriesAvailable"
            ><v-tooltip activator="parent" location="bottom"
              >Raadpleeg de selectie-overzichten</v-tooltip
            >Selectie-overzicht</v-chip
          >
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "seasonsPage",
  data: () => ({
    isLoading: false,
    error: null,
    seasonGuid: null,
    seasons: [],
  }),
  computed: {
    seasonList() {
      const seasons = this.$store.getters["seasons/seasons"];

      if (seasons) {
        const list = [];
        seasons.forEach((season) => {
          let name = season.description;

          let info = "";
          if (season.period) {
            info = season.period;
          }

          list.push({
            seasonGuid: season.guid,
            name: name,
            info: info,
          });
        });

        return list;
      }

      return [];
    },
    season() {
      const season = this.$store.getters["getSeason"];
      return season;
    },
    isSeriesAvailable() {
      return this.$store.getters["seasons/seasonSeriesAvailable"];
    },
  },
  methods: {
    async loadSeasons() {
      this.isLoading = true;

      try {
        await this.$store
          .dispatch("seasons/loadSeasons", {})
          .then((this.seasonGuid = this.$store.getters["getSeasonGuid"]));
      } catch (err) {
        this.error =
          err.message ||
          "Er trad een probleem op bij het ophalen van de seasons!";
      }

      this.isLoading = false;
    },
    itemProps(item) {
      if (item) {
        return {
          title: item.name,
          subtitle: item.info,
        };
      }

      return null;
    },
    async loadSeries() {
      this.isLoading = true;

      try {
        await this.$store.dispatch("seasons/loadSeasonSeries", {
          seasonGuid: this.seasonGuid,
        });
      } catch (err) {
        this.error =
          err.message ||
          "Er trad een probleem op bij het ophalen van de reeksen!";
      }

      this.isLoading = false;
    },
    showSeries() {
      this.$router.push("/seasonserie");
    },
  },
  watch: {
    seasonGuid: function (val) {
      let currentSeason = null;
      const seasons = this.$store.getters["seasons/seasons"];
      if (seasons) {
        const season = seasons.filter((s) => {
          return s.guid == val ? true : false;
        });

        if (season && season.length > 0) {
          currentSeason = season[0];
        }
      }

      this.$store.dispatch("setSeason", currentSeason);
      this.loadSeries();
    },
  },
  created() {
    this.loadSeasons();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
html {
  font-family: "Roboto", sans-serif;
}

.main {
  margin: 30px auto;
  border: 1px solid var(--var-font-border);
  box-shadow: 0 2px 3px #ccc;
}

.info {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  line-height: 40px;
}

.details {
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.chips {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
