<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="main">
    <v-autocomplete
      v-model="registrationGuid"
      :items="registrationList"
      :item-props="itemProps"
      item-title="name"
      item-value="registrationGuid"
      label="Zoek combinatie"
      no-data-text="Geen combinaties"
      placeholder="Selecteer combinatie"
      single-line
      clearable
    ></v-autocomplete>
    <div v-if="registrationAvailable" class="info">
      <v-card>
        <h1>
          <v-icon>mdi-account-outline</v-icon>&nbsp;{{ registration.name }}
        </h1>
        <v-divider :thickness="5"></v-divider>
        <div class="details">
          <div>
            <v-icon>mdi-horse-variant</v-icon>&nbsp;
            <strong>{{ registration.horse }}</strong>
          </div>
          <div v-if="registration.club != ''">
            <v-icon>mdi-account-group</v-icon>&nbsp;
            <strong>{{ registration.club }}</strong>
          </div>
          <div v-if="registration.number != ''">
            Nr: <strong>{{ registration.number }}</strong>
          </div>
          <div v-if="registration.combination != ''">
            Combinatie: <strong>{{ registration.combination }}</strong>
          </div>
          <div>
            <v-icon>mdi-format-list-text</v-icon>&nbsp; Reeks/ring:
            <strong>{{ registration.ring }}</strong>
          </div>

          <div v-if="registration.position && registration.position != ''">
            <v-icon>mdi-format-list-numbered</v-icon>&nbsp;
            {{ registration.position }}e plaats
          </div>
          <div v-else>
            <v-icon>mdi-format-list-numbered</v-icon>&nbsp;{{
              registration.total
            }}
          </div>
        </div>
        <v-divider :thickness="5"></v-divider>

        <div class="chips d-flex justify-center align-center ga-2 mt-2">
          <v-chip
            prepend-icon="mdi-format-list-text"
            label
            @click="showRanking(registration.ringGuid)"
            ><v-tooltip activator="parent" location="bottom"
              >Raadpleeg het klassement {{ registration.ringTitle }}</v-tooltip
            >Klassement {{ registration.ring }}
          </v-chip>
          <v-chip
            prepend-icon="mdi-heart-outline"
            label
            @click="addFollowing(registration.registrationGuid)"
            v-if="!isFollowing(registration.registrationGuid)"
            ><v-tooltip activator="parent" location="bottom"
              >Deze combinatie volgen</v-tooltip
            >Volgen
          </v-chip>
          <v-chip
            prepend-icon="mdi-heart"
            label
            @click="removeFollowing(registration.registrationGuid)"
            v-if="isFollowing(registration.registrationGuid)"
            ><v-tooltip activator="parent" location="bottom"
              >Deze combinatie niet meer volgen</v-tooltip
            >Niet meer Volgen
          </v-chip>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "registrationsPage",
  data: () => ({
    isLoading: false,
    error: null,
    registrationGuid: null,
    registrations: [],
  }),
  computed: {
    registrationList() {
      const registrations = this.$store.getters["registrations/registrations"];

      if (registrations) {
        const list = [];
        registrations.forEach((registration) => {
          let name = registration.number + " - " + registration.name;

          let info = registration.horse + ", " + registration.ring;
          list.push({
            registrationGuid: registration.registrationGuid,
            name: name,
            info: info,
          });
        });

        return list;
      }

      return [];
    },
    registration() {
      const r = this.$store.getters["selectedRegistration"];
      return r;
    },
    registrationAvailable() {
      const registration = this.$store.getters["selectedRegistration"];
      return this.registrationGuid &&
        this.registrationGuid != "" &&
        registration &&
        registration.registrationGuid != ""
        ? true
        : false;
    },
  },
  methods: {
    itemProps(item) {
      if (item) {
        return {
          title: item.name,
          subtitle: item.info,
        };
      }

      return;
    },
    showRanking(ringGuid) {
      this.$router.push("/ranking/" + ringGuid);
    },
    isFollowing(registrationGuid) {
      const following = this.$store.getters["following"];
      return following.includes(registrationGuid) ? true : false;
    },
    addFollowing(registrationGuid) {
      const payload = {
        registrationGuid: registrationGuid,
      };
      this.$store.dispatch("addFollowing", payload);
    },
    removeFollowing(registrationGuid) {
      const payload = {
        registrationGuid: registrationGuid,
      };
      this.$store.dispatch("removeFollowing", payload);
    },
  },
  watch: {
    registrationGuid: function (val) {
      let currentRegistration = null;
      const registrations = this.$store.getters["registrations/registrations"];
      if (registrations) {
        const registration = registrations.filter((r) => {
          return r.registrationGuid == val ? true : false;
        });

        if (registration && registration.length > 0) {
          currentRegistration = registration[0];
        }
      }

      this.$store.dispatch("setRegistration", currentRegistration);
    },
  },
  created() {
    const registration = this.$store.getters["selectedRegistration"];
    if (registration && registration.registrationGuid) {
      this.registrationGuid = registration.registrationGuid;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
html {
  font-family: "Roboto", sans-serif;
}

.main {
  margin: 30px auto;
  border: 1px solid var(--var-font-border);
  box-shadow: 0 2px 3px #ccc;
}

.info {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  line-height: 40px;
}

.details {
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.chips {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
